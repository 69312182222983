import React from 'react';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';
import upArrow from '../../assets/icons/sort_asc.png';
import bothArrow from '../../assets/icons/sort_both.png';
import downArrow from '../../assets/icons/sort_desc.png';

export const DataTable = (props) => {
  const { columns, data, rowClickHandler, filtering, getRowId } = props;
  let tableOptions = {
    columns,
    data,
    getRowId,
    autoResetFilters: false,
  };

  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  let tablePlugins = [];
  if (filtering) {
    tablePlugins = [...tablePlugins, useFilters];
    tableOptions = { ...tableOptions, ...filterTypes };
  }
  tablePlugins = [...tablePlugins, useSortBy, usePagination];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(tableOptions, ...tablePlugins);

  return (
    <>
      <table className="w-full border border-gray-400" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="py-3 px-6 bg-green text-white whitespace-nowrap"
                  align="left"
                  valign="top">
                  {column.render('Header')}
                  {column.canSort && (
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img src={upArrow} alt="up-arrow" className="inline h-6" />
                        ) : (
                          <img src={downArrow} alt="down-arrow" className="inline h-6" />
                        )
                      ) : (
                        <img src={bothArrow} alt="both-arrow" className="inline h-6" />
                      )}
                    </span>
                  )}
                  <div className="text-black">{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => {
                  if (rowClickHandler) {
                    rowClickHandler(row.original);
                  }
                }}
                className={rowClickHandler && 'cursor-pointer hover:bg-gray-300 hover:border-black'}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="py-3 px-6 border-t border-gray-400">
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex items-center my-4">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className={`px-2 py-1 bg-gray-400 mr-2 font-medium ${
            canPreviousPage ? 'hover:text-white hover:bg-black' : 'opacity-40'
          }`}
          style={{ minWidth: '30px' }}>
          {'<<'}
        </button>{' '}
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className={`px-2 py-1 bg-gray-400 mr-2 font-medium ${
            canPreviousPage ? 'hover:text-white hover:bg-black' : 'opacity-40'
          }`}
          style={{ minWidth: '30px' }}>
          {'<'}
        </button>{' '}
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className={`px-2 py-1 bg-gray-400 mr-2 font-medium ${
            canNextPage ? 'hover:text-white hover:bg-black' : 'opacity-40'
          }`}
          style={{ minWidth: '30px' }}>
          {'>'}
        </button>{' '}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className={`px-2 py-1 bg-gray-400 mr-2 font-medium ${
            canNextPage ? 'hover:text-white hover:bg-black' : 'opacity-40'
          }`}
          style={{ minWidth: '30px' }}>
          {'>>'}
        </button>{' '}
        <span className="mx-4">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span className="mx-4">
          Go to page:{' '}
          <input
            className="border border-gray-600 px-2 py-1"
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              let value = e.target.value;
              if (value > pageCount) {
                value = pageCount;
                e.target.value = value;
              }
              const page = Number(value) - 1;
              gotoPage(page);
            }}
            max={pageCount}
            min={1}
            style={{ width: '100px' }}
          />
        </span>
        <select
          className="border border-gray-600 px-2 py-1"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}>
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};
