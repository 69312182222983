import React from 'react';
import cssClasses from './Modal.module.css';
import Backdrop from '../Backdrop/Backdrop';
const Modal = (props) => {
  const { show, dismissModal, className, children } = props;

  return (
    <>
      <Backdrop show={show} click={dismissModal} />
      <div
        className={`${cssClasses.Modal} ${className ? className : ''}`}
        style={{
          transform: show ? 'translateY(0)' : 'translateY(-100vh)',
          opacity: show ? '1' : '0',
        }}
      >
        {children}
      </div>
    </>
  );
};
export default React.memo(Modal, (prevProps, nextProps) => {
  return (
    nextProps.show === prevProps.show &&
    prevProps.children === nextProps.children
  );
});
