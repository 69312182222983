import { format } from 'date-fns';
import { EStatus } from '../components/StatusBadge/StatusBadge';

export const uniqueTextFieldKeys = Object.freeze({
  commentActions: 'Comment Box: Actions agreed',
  commentImprove: 'Comment Box: Identified areas for improvement',
  commentIssues: 'Comment Box: Other issues discussed',
  commentObservations: 'Comment Box: Observations made',
  contractName: 'Contract Name',
  descriptionOfWorks: 'Description of Works',
  directorOrManager: 'Director/Manager Name',
  incident: 'Incident',
  myProblem: 'My Problem',
  myIdea: 'My Idea',
  supervisor: 'Supervisor/Project Manager',
  theirName: 'Their name',
  whatTheKudosFor: 'What the kudos is for',
  whatTheProblemIs: 'What the problem is',
  whyItWouldHelp: 'Why I think it would help',
});

const getAssignedToFromName = (type, userName, toFromId) => {
  if (userName !== 'Anonymous user' && userName !== '') {
    return userName;
  } else if ((userName === 'Anonymous user' || userName === '') && toFromId) {
    return <span className="text-red-500">Deleted user</span>;
  } else {
    return userName;
  }
};

export const getCategory = ({ title }) => {
  return title || '';
};
export const getSubmittedBy = (row) => {
  return getAssignedToFromName(1, row.name, row.userId);
};
export const getEmail = ({ email }) => {
  return email || '';
};
export const getDate = ({ createdAt }) => {
  return createdAt ? format(new Date(createdAt), 'do LLL yyyy HH:mm X') : '';
};
export const getAssignedTo = (row) => {
  return getAssignedToFromName(2, row.assignedTo, row.assignedToId);
};
export const getNotes = ({ notes }) => {
  return notes || '';
};
export const getStatus = ({ status }) => {
  switch (status) {
    case EStatus.pending:
      return 'Pending';
    case EStatus.inProgress:
      return 'In Progress';
    case EStatus.resolved:
      return 'Resolved';
    default:
      return null;
  }
};
export const getLink = ({ responseId = 0 }) => {
  return `=HYPERLINK("${window.location.origin}/submissions/${responseId}","See Details")`;
};
