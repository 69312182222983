import React, { useCallback, useContext, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import qs from 'qs';
import { format, subDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import axiosInstance from '../../axios/axios-instance';
import { serviceRoutes } from '../../serviceRoutes';
import { AppContext } from '../../contexts/AppContext';
import Button from './../Shared/Button';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { EStatus } from '../StatusBadge/StatusBadge';

const initialDateRange = [
  {
    startDate: null,
    endDate: null,
    key: 'selection',
  },
];

export const SubmissionFiltering = ({ loadResponses }) => {
  const { authState } = useContext(AppContext);
  const [selectedFormIds, setSelectedFormIds] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedAdminUserIds, setSelectedAdminUserIds] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [dateRange, setDateRange] = useState(initialDateRange);

  const onClearFilters = () => {
    setSelectedFormIds([]);
    setSelectedUserIds([]);
    setSelectedAdminUserIds([]);
    setSelectedStatuses([]);
    setDateRange(initialDateRange);
  };

  const getForms = useCallback(
    async (inputValue) => {
      if (authState.accessToken !== null) {
        const { data: forms } = await axiosInstance.get(serviceRoutes.getFormsWithTitle(inputValue), {
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
          },
        });

        return forms.map((form) => ({
          value: form.formId,
          label: form.title,
        }));
      }
    },
    [authState.accessToken],
  );

  const getUsers = useCallback(
    async (inputValue) => {
      if (authState.accessToken !== null) {
        const { data: users } = await axiosInstance.get(serviceRoutes.getUsersWithName(inputValue), {
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
          },
        });

        return users.map((user) => ({
          value: user.userId,
          label: `${user.name} [${user.email}]`,
        }));
      }
    },
    [authState.accessToken],
  );

  const getAdminUsers = useCallback(
    async (inputValue) => {
      if (authState.accessToken !== null) {
        const { data: users } = await axiosInstance.get(serviceRoutes.getAdminUsersWithName(inputValue), {
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
          },
        });

        return users.map((user) => ({
          value: user.userId,
          label: `${user.name} [${user.email}]`,
        }));
      }
    },
    [authState.accessToken],
  );

  const submitSearchFilters = async () => {
    const { data: responseResult } = await axiosInstance.get(serviceRoutes.getSubmissions, {
      headers: {
        Authorization: `Bearer ${authState.accessToken}`,
      },
      params: {
        form: selectedFormIds.map((form) => form.value),
        user: selectedUserIds.map((user) => user.value),
        assignedTo: selectedAdminUserIds.map((admin) => admin.value),
        status: selectedStatuses.map((status) => status.value),
        startDate: dateRange[0].startDate ? format(dateRange[0].startDate, 'yyyy-MM-d') : null,
        endDate: dateRange[0].endDate ? format(dateRange[0].endDate, 'yyyy-MM-d') : null,
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
    });

    loadResponses(responseResult);
  };

  const selectLastNDays = (n) => {
    const now = new Date();
    const tempDateRange = [{ ...dateRange[0] }];
    tempDateRange[0].startDate = subDays(now, n);
    tempDateRange[0].endDate = now;
    setDateRange(tempDateRange);
  };

  return (
    <div className="lg:flex w-full justify-evenly">
      <div className="flex-1 flex h-96 justify-between lg:order-3 mb-10 lg:mb-0">
        <div className="flex flex-col justify-center w-2/4">
          <Button secondary className="mb-2" onClick={() => selectLastNDays(6)}>
            Last 7 days
          </Button>
          <Button secondary className="mb-2" onClick={() => selectLastNDays(29)}>
            Last 30 days
          </Button>
          <Button secondary className="whitespace-nowrap" onClick={() => setDateRange(initialDateRange)}>
            Reset Date Range
          </Button>
        </div>
        <DateRangePicker
          className="ml-4 w-3/4"
          ranges={dateRange}
          editableDateInputs={true}
          moveRangeOnFirstSelection={true}
          startDatePlaceholder="From"
          endDatePlaceholder="To"
          rangeColors={['#448d44']}
          onChange={(range) => setDateRange([range.selection])}
          showSelectionPreview={false}
          maxDate={new Date()}
          dateDisplayFormat="yyyy-MM-d"
        />
      </div>
      <div className="border-gray-300 border-2 w-100 mx-6 hidden lg:block order-2" />
      <div className="flex flex-col flex-1 justify-center lg:order-1">
        <AsyncSelect
          className="mb-4"
          isMulti={true}
          loadOptions={getForms}
          defaultOptions={true}
          onChange={(value) => setSelectedFormIds(value || [])}
          value={selectedFormIds || []}
          placeholder="Select Category"
        />
        <AsyncSelect
          className="mb-4"
          isMulti={true}
          loadOptions={getUsers}
          onChange={(value) => setSelectedUserIds(value || [])}
          value={selectedUserIds || []}
          placeholder={'Search users'}
        />
        <AsyncSelect
          className="mb-4"
          isMulti={true}
          loadOptions={getAdminUsers}
          onChange={(value) => setSelectedAdminUserIds(value || [])}
          value={selectedAdminUserIds || []}
          placeholder={'Search admin users'}
        />
        <Select
          className="mb-4"
          isMulti={true}
          onChange={(value) => setSelectedStatuses(value || [])}
          value={selectedStatuses || []}
          placeholder="Select status"
          options={[
            { value: EStatus.pending, label: 'Pending' },
            { value: EStatus.inProgress, label: 'In Progress' },
            { value: EStatus.resolved, label: 'Resolved' },
          ]}
        />
        <div className="flex my-4">
          <Button className="mr-4" onClick={submitSearchFilters}>
            Submit
          </Button>
          <Button secondary className="mr-4" onClick={onClearFilters}>
            Clear
          </Button>
        </div>
      </div>
    </div>
  );
};
