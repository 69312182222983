import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import axios from '../axios/axios-instance';
import Button from '../components/Shared/Button';
import { appRoutes } from '../constants/appRoutes';
import { serviceRoutes } from '../serviceRoutes';

export const ForgotPassword = () => {
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
  const { register, formState, handleSubmit } = useForm();
  const [isSending, setSending] = useState(false);

  const onSubmit = (data) => {
    setSending(true);
    axios.post(serviceRoutes.forgotPassword, data).then((res) => {
      if (res.status === 200) {
        setSubmissionSuccessful(true);
        localStorage.setItem('passResetEmail', data.email);
      }
    });
  };

  useEffect(() => {
    const email = localStorage.getItem('passResetEmail');
    if(email != null)
      axios.post(serviceRoutes.checkPasswordReset, {email: email}).then((res) => {
        console.log(res.data, Date.now()/1000 - res.data.exp)
        if (res.status === 200) {
          setSubmissionSuccessful(true);
        } else {
          localStorage.removeItem('passResetEmail');
        }
      });
  }, [])

  return (
    <div className="h-full flex flex-col items-center">
      {!submissionSuccessful ? (
        <>
          <h1 className="text-3xl text-primary font-medium mb-4">Forgot your password?</h1>
          <p className="max-w-sm text-lg text-gray-600">
            Please enter the email you used at the time of registration to get the password reset instructions
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-96 mb-48" id="forgot-password-form">
            <input
              className="py-2 px-4 text-base rounded-md border border-secondary hover:border-black mt-8"
              placeholder="Email"
              name="email"
              type="email"
              disabled={formState.isSubmitting}
              ref={register({
                required: 'Your email is required',
                pattern: {
                  value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'This is not a valid email',
                },
              })}
            />
            <Button type="submit" className={'mt-8 '} disabled={isSending} form="forgot-password-form">
            {isSending ? 'Sending email..' : 'Email me a recovery link'}
            </Button>
          </form>
        </>
      ) : (
        <div className="max-w-sm bg-white border-2 border-gray-300 p-6 rounded-md tracking-wide shadow-lg">
          <p className="text-lg mb-4">
            We have sent an email. Please follow the instructions in the email to reset your password
          </p>
          <Link
            className="underline text-center text-blue-600 hover:text-blue-800 visited:text-purple-600"
            to={appRoutes.login}>
            Go back to login page?
          </Link>
        </div>
      )}
    </div>
  );
};
