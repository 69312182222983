import React, { useContext, useState } from 'react';
import Button from '../components/Shared/Button';
import { useForm } from 'react-hook-form';
import axios from '../axios/axios-instance';
import { ERROR_MESSAGES } from '../constants/errorMessages';
import { serviceRoutes } from '../serviceRoutes';
import { AppContext } from '../contexts/AppContext';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { appRoutes } from '../constants/appRoutes';
import { Spinner } from '../components/Shared/Spinner';
import aaokLogo from '../assets/logo/aaok_logo.png'; // with import

const Login = () => {
  const { authState, setAuthState } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm();
  const { state } = useLocation();

  const onSubmit = (loginData) => {
    setIsLoading(true);
    axios
      .post(serviceRoutes.login, loginData)
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        if (!data.error) {
          if (data.accessToken !== null && data.refreshToken !== null) {
            localStorage.setItem('refreshToken', data.refreshToken);
            setAuthState(data);
          }
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            setErrorMessage(err.response.data.error.message || ERROR_MESSAGES.invalid);
          } else {
            setErrorMessage(ERROR_MESSAGES.invalid);
          }
        } else if (err.request) {
          setErrorMessage(ERROR_MESSAGES.network);
        } else {
          setErrorMessage(ERROR_MESSAGES.unexpected);
        }
        setIsLoading(false);
      });
  };

  if (authState.accessToken && authState.refreshToken) {
    return <Redirect to={state?.from || '/'} />;
  }

  return (
    <>
      {isLoading ? (
        <div className="w-full flex justify-center py-40">
          <Spinner className="h-12 w-12 text-primary" />
        </div>
      ) : (
        <div className="h-full flex flex-col items-center">
          <img src={aaokLogo} alt="Associated Asphalt Contracting" width="240px" />
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-80 lg:w-96 mb-48">
            <input
              className="py-2 px-4 text-base rounded-md border border-secondary hover:border-black mt-8"
              placeholder="Email"
              name="email"
              type="email"
              ref={register({
                required: 'Your email is required',
                pattern: {
                  value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'This is not a valid email',
                },
              })}
            />
            {errors.email && <span className="text-red-600 font-medium">{errors.email.message}</span>}
            <input
              className="py-2 px-4 text-base rounded-md border border-secondary hover:border-black mt-4"
              placeholder="Password"
              name="password"
              type="password"
              ref={register({ required: 'Your password is required' })}
            />
            {errors.password && <span className="text-red-600 font-medium">{errors.password.message}</span>}
            <Button type="submit" className="mt-8 mb-4" isLoading={isLoading}>
              LOGIN
            </Button>
            <div className="text-center">
              <Link
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                to={appRoutes.forgotPassword}>
                Forgot your password?
              </Link>
            </div>
            {errorMessage && <p className="text-red-500 mt-5">{'Error: ' + errorMessage}</p>}
          </form>
        </div>
      )}
    </>
  );
};

export default Login;
