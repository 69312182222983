import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoutIcon } from '../../assets/icons/LogoutIcon.svg';
import { AppContext } from '../../contexts/AppContext';
import Button from './../Shared/Button';
import Modal from '../Modal/Modal';
import aaokLogo from '../../assets/logo/aaok_logo_without_transparency.png';

import './Navbar.css';

export const Navbar = (props) => {
  const { authState, setAuthState } = useContext(AppContext);

  console.log(authState.permissions);

  const { onLogout } = props;
  const [showModal, setShowModal] = useState(null);

  const { latestDisabledUsersCount, fetchLatestDisabledUsersCount } = useContext(AppContext);

  useEffect(() => {
    fetchLatestDisabledUsersCount();
  }, [fetchLatestDisabledUsersCount]);

  return (
    <>
      <Modal show={showModal} dismissModal={() => setShowModal(false)}>
        <div>
          <p className="text-2xl mx-8 my-4">Logout</p>
          <p className="text-lg mx-8 mb-8">Are you sure you want to logout?</p>
          <div className="flex flex-row justify-around">
            <Button secondary onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button onClick={() => onLogout()}>Confirm</Button>
          </div>
        </div>
      </Modal>
      <div className="h-18 w-full flex bg-bgNavigator text-white items-center px-8 py-2 justify-between">
        <div className="flex items-center">
          <div className="flex items-center">
            <div className="rounded-full overflow-hidden w-11">
              <img src={aaokLogo} alt="Associated Asphalt Contracting" />
            </div>
          </div>
          <ul className="flex ml-8 text-lg text-secondary ">
            <li>
              <NavLink to="/submissions" className="mr-4 font-bold hover:text-primary" activeClassName="text-white">
                Logs
              </NavLink>
            </li>
            {authState.permissions?.user && authState.permissions.user['read:any'] && (
              <li>
                <NavLink to="/users" className="mr-4 font-bold hover:text-primary flex" activeClassName="text-white">
                  Users
                  {Number(latestDisabledUsersCount) > 0 && (
                    <div className="hidden notification-bubble bg-primary text-white text-sm font-medium rounded-full text-center ml-1">
                      {latestDisabledUsersCount}
                    </div>
                  )}
                </NavLink>
              </li>
            )}
            {authState.permissions?.link && authState.permissions.user['read:any'] && (
              <li>
                <NavLink to="/links" className="mr-4 font-bold hover:text-primary" activeClassName="text-white">
                  Useful Links
                </NavLink>
              </li>
            )}
          </ul>
        </div>
        <button
          className="bg-grey hover:bg-primary text-white rounded-md py-1 px-2 text-base flex justify-center items-center"
          onClick={() => setShowModal(true)}>
          <LogoutIcon />
          <span className="hidden lg:inline-block ml-2">Logout</span>
        </button>
      </div>
    </>
  );
};
