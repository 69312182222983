import React from 'react';

export const ColumnFilter = (props) => {
  const {
    column: { filterValue, preFilteredRows, setFilter },
  } = props;

  const count = preFilteredRows.length;

  return (
    <input
      className="rounded border border-gray-400 hover:border-gray-500 cursor-pointer px-2 py-1 w-full"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};
