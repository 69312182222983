export const serviceRoutes = {
  login: '/auth/admin/login',
  logout: '/auth/logout',
  forgotPassword: '/auth/forgot-password',
  checkPasswordReset: '/auth/check-password-reset',
  resetPassword: '/auth/reset-password',
  users: '/users',
  refreshToken: '/auth/refresh-token',
  getSubmissions: '/responses',
  getSubmissionDetails: (id) => `/responses/${id}`,
  setAdminFields: (id) => `/responses/${id}`,
  getFormsWithTitle: (title) => `/forms/name?title=${title}`,
  getUsersWithName: (name) => `/users/name?name=${name}`,
  getAdminUsersWithName: (name) => `/users/name?name=${name}&admin=true`,
  updateUserStatus: (userId) => `/users/change-status/${userId}`,
  updateUserVisibility: (userId) => `/users/change-visibility/${userId}`,
  updateUserAdminStatus: (userId) => `/users/change-admin-status/${userId}`,
  getDisabledUsersStatus: '/users/get-disabled-users',
  deleteUser: (userId) => `/users/delete/${userId}`,

  getLinks: '/links',
  createLink: '/links',
  getLinkDetails: (id) => `/links/${id}`,
  updateLink: (id) => `/links/${id}`,
  deleteLink: (id) => `/links/${id}`,
};
