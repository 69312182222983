import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { LayoutContainer } from '../Containers/LayoutContainer';
import { AppContext } from '../../contexts/AppContext';
import { appRoutes } from '../../constants/appRoutes';

export const PrivateRoute = ({ children, ...rest }) => {
  const { authState } = useContext(AppContext);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return authState.accessToken !== null && authState.refreshToken !== null ? (
          <LayoutContainer>{children}</LayoutContainer>
        ) : (
          <Redirect
            to={{
              pathname: appRoutes.login,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};
