import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { unparse } from 'papaparse/papaparse.min';
import { format } from 'date-fns';
import { Spinner } from '../components/Shared/Spinner';
import { DataTable } from '../components/DataTable/DataTable';
import axios from '../axios/axios-instance';
import { SubmissionFiltering } from '../components/DataTable/SubmissionFiltering';
import { AppContext } from '../contexts/AppContext';
import { serviceRoutes } from '../serviceRoutes';
import StatusBadge from '../components/StatusBadge/StatusBadge';
import Button from '../components/Shared/Button';
import {
  getAssignedTo,
  getCategory,
  getDate,
  getEmail,
  getLink,
  getNotes,
  getStatus,
  getSubmittedBy,
  uniqueTextFieldKeys,
} from '../utils/csvHelper';

const getAssignedToFromName = (type, userName, toFromId) => {
  if (userName !== 'Anonymous user' && userName !== '') {
    return userName;
  } else if ((userName === 'Anonymous user' || userName === '') && toFromId != null && toFromId != '') {
    return <span className="text-red-500">Deleted user</span>;
  } else {
    return userName;
  }
};

const columnConfiguration = [
  {
    Header: 'Category',
    accessor: 'title',
    disableSortBy: true,
  },
  {
    Header: 'Submitted by',
    accessor: 'name',
    Cell: (props) => {
      return getAssignedToFromName(1, props.value, props.row.original.userId);
    },
    disableSortBy: true,
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
    Cell: ({ value }) => {
      return format(new Date(value), 'do LLL yyyy HH:mm');
    },
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Assigned to',
    accessor: 'assignedTo',
    Cell: (props) => {
      return getAssignedToFromName(2, props.value, props.row.original.assignedToId);
    },
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => {
      return <StatusBadge status={value} />;
    },
    disableSortBy: true,
  },
];

const Submissions = () => {
  const { authState } = useContext(AppContext);
  const [responses, setResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();

  const handleExportCsv = async () => {
    try {
      console.log(responses);
      const csvFile = unparse(generateCSVData(responses), { quotes: true, dynamicTyping: true, error: '' });
      const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, `AAOK-Submissions_${new Date().toLocaleDateString()}.csv`);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', `AAOK-Submissions_${new Date().toLocaleDateString()}.csv`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch (e) {
      console.log(e.message);
      setErrorMessage(e.message);
    }
  };

  const generateCSVData = (data) => {
    const csvHead = [
      'Category',
      'Submitted by',
      'Email',
      'Date',
      'Date Submitted',
      'Assigned to',
      'Status',
      'Notes',
      'Link to Details Page',
    ];
    const defaultHeadersCount = csvHead.length;
    const dynamicHeaderCount = Object.keys(uniqueTextFieldKeys).length;
    for (const key in uniqueTextFieldKeys) {
      if (uniqueTextFieldKeys.hasOwnProperty(key)) {
        csvHead.push(uniqueTextFieldKeys[key]);
      }
    }

    let finalCsvData = data.map((r) => {
      const x = Array(defaultHeadersCount + dynamicHeaderCount).fill('N/A');

      x[0] = getCategory(r).replace(/’/g, "'");
      x[1] = getSubmittedBy(r);
      x[2] = getEmail(r);
      x[3] = getDate(r);
      x[5] = getAssignedTo(r);
      x[6] = getStatus(r);
      x[7] = getNotes(r).replace(/’/g, "'");
      x[8] = getLink(r);
      r.extraFields &&
        r.extraFields.forEach((y) => {
          switch (y.fieldKey) {
            case 'autoDateTime':
              x[4] = y.value;
              return;
            case 'commentActions':
              x[9] = y.value.replace(/’/g, "'");
              return;
            case 'commentImprove':
              x[10] = y.value.replace(/’/g, "'");
              return;
            case 'commentIssues':
              x[11] = y.value.replace(/’/g, "'");
              return;
            case 'commentObservations':
              x[12] = y.value.replace(/’/g, "'");
              return;
            case 'contractName':
              x[13] = y.value.replace(/’/g, "'");
              return;
            case 'descriptionOfWorks':
              x[14] = y.value.replace(/’/g, "'");
              return;
            case 'directorOrManager':
              x[15] = y.value.replace(/’/g, "'");
              return;
            case 'incident':
              x[16] = y.value.replace(/’/g, "'");
              return;
            case 'myProblem':
              x[17] = y.value.replace(/’/g, "'");
              return;
            case 'myIdea':
              x[18] = y.value.replace(/’/g, "'");
              return;
            case 'supervisor':
              x[19] = y.value.replace(/’/g, "'");
              return;
            case 'theirName':
              x[20] = y.value.replace(/’/g, "'");
              return;
            case 'whatTheKudosFor':
              x[21] = y.value.replace(/’/g, "'");
              return;
            case 'whatTheProblemIs':
              x[22] = y.value.replace(/’/g, "'");
              return;
            case 'whyItWouldHelp':
              x[23] = y.value.replace(/’/g, "'");
              return;
            default:
              return;
          }
        });
      return x;
    });
    return {
      fields: csvHead,
      data: finalCsvData,
    };
  };

  const submissionClickHandler = useCallback(
    ({ responseId }) => {
      history.push('/submissions/' + responseId);
    },
    [history],
  );

  const getRowId = useCallback(({ responseId }) => {
    return responseId;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(serviceRoutes.getSubmissions, {
        headers: {
          Authorization: `Bearer ${authState.accessToken}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (!res.data.error) {
          setResponses(res.data);
        } else {
          setErrorMessage('There was a problem fetching responses.');
        }
      })
      .catch(() => setErrorMessage('There is a problem with the network.'));
  }, [authState.accessToken]);

  return (
    <div className="w-full pb-40">
      <p className="text-3xl text-primary font-medium ml-8 my-8">Logs</p>
      {isLoading ? (
        <div className="w-full flex justify-center py-40">
          <Spinner className="h-12 w-12 text-primary" />
        </div>
      ) : (
        <>
          <div className="mb-16">
            <SubmissionFiltering loadResponses={setResponses} />
          </div>
          {/* isDisabled={responses.length < 1}  */}
          <Button onClick={handleExportCsv} className="ml-auto mb-4">
            Export to CSV
          </Button>
          <DataTable
            rowClickHandler={submissionClickHandler}
            columns={columnConfiguration}
            data={responses || []}
            getRowId={getRowId}
          />
        </>
      )}
    </div>
  );
};

export default Submissions;
