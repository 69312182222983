import React from 'react';
import Spinner from './Spinner';

const Button = ({ secondary, isLoading , isDisabled , type, children, className, onClick }) => {
  return (
    <button
      onClick={onClick}
      disabled={isLoading || isDisabled}
      type={type}
      style={{ minHeight: '2.5rem', minWidth: '10rem' }}
      className={`${className} ${secondary ? 'bg-gray-300' : 'bg-grey text-white'}
        ${!isLoading ? 'hover:bg-primary hover:text-white' : ''} 
        ${isDisabled ? 'opacity-50' : ''}
        rounded-md py-2 px-4 text-base flex justify-center items-center`}>
      {isLoading ? <Spinner className='h-5 w-5' /> : children}
    </button>
  );
};

export default Button;
