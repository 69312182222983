import React, { useCallback, useContext } from 'react';
import axios from '../../axios/axios-instance';
import { AppContext } from '../../contexts/AppContext';
import { serviceRoutes } from '../../serviceRoutes';
import { Navbar } from '../Navbar/Navbar';

export const LayoutContainer = (props) => {
  const { authState, setAuthState } = useContext(AppContext);

  const onLogout = useCallback(() => {
    axios
      .post(serviceRoutes.logout, { refreshToken: authState.refreshToken })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setAuthState({
          accessToken: null,
          refreshToken: null,
        });
      });
  }, [authState.refreshToken, setAuthState]);

  return (
    <div className="w-full h-full min-h-screen">
      <Navbar onLogout={onLogout} />
      <div className="w-full flex flex-col items-center px-4 lg:px-10 overflow-x-auto">{props.children}</div>
    </div>
  );
};
