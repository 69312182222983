import axios from 'axios';

const { REACT_APP_BASE_API_URL } = process.env;

const axiosInstance = axios.create({
  baseURL: REACT_APP_BASE_API_URL,
  responseType: 'json',
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    'Access-Control-Allow-Origin': '*',
  },
});

export default axiosInstance;
