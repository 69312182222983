import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';
import Switch from 'react-switch';
import { Spinner } from '../components/Shared/Spinner';
import { DataTable } from '../components/DataTable/DataTable';
import axios from '../axios/axios-instance';
import Modal from '../components/Modal/Modal';
import Button from '../components/Shared/Button';
import { AppContext } from '../contexts/AppContext';
import axiosInstance from '../axios/axios-instance';
import { ColumnFilter } from '../components/DataTable/DefaultColumnFilter';
import { ERROR_MESSAGES } from '../constants/errorMessages';
import { serviceRoutes } from '../serviceRoutes';
import { Page404 } from './404/404';
import '../assets/customradiobutton.css';

const switchTypes = {
  userStatus: 'USER_STATUS',
  userVisibility: 'USER_VISIBILITY',
  userIsAdmin: 'USER_IS_ADMIN',
};

const roleNames = {
  admin: 'Admin',
  line_manager: 'Line Manager',
  team_member: 'Team Member',
};

const filterAdmins = (rows, id, filterValue) => {
  return rows.filter((row) => {
    const rowValue = String(row.values[id]);
    if (rowValue === undefined) {
      return true;
    } else {
      return filterValue.split(',').includes(rowValue);
    }
  });
};

const ConfirmationModal = ({ open, switchData, closeModal, onConfirm }) => {
  const makeSwitchOnText =
    switchData && switchData.type === switchTypes.userStatus ? 'Enable' : 'give admin permissions to';

  const makeSwitchOffText =
    switchData && switchData.type === switchTypes.userStatus ? 'Disable' : 'revoke admin permissions from';

  return (
    <Modal show={open} dismissModal={closeModal}>
      {switchData ? (
        <div>
          <p className="text-2xl mx-8 my-4">{switchData.status === 1 ? 'Disable' : 'Enable'} user?</p>
          <p className="text-lg mx-8 mb-8">
            Are you sure you want to{' '}
            <span className="font-bold">{switchData.status === 1 ? makeSwitchOffText : makeSwitchOnText}</span> user{' '}
            {switchData.name}?
          </p>
          <div className="flex flex-row justify-around">
            <Button secondary onClick={closeModal}>
              Cancel
            </Button>
            <Button onClick={onConfirm}>Confirm</Button>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};

const VisibilityConfirmationModal = ({ open, switchDataVisible, closeModal, onConfirm }) => {
  return (
    <Modal show={open} dismissModal={closeModal}>
      {switchDataVisible ? (
        <div>
          <p className="text-2xl mx-8 my-4">{switchDataVisible.status === 1 ? 'Hide' : 'Visible'} user?</p>
          <p className="text-lg mx-8 mb-8">
            Are you sure you want to{' '}
            <span className="font-bold">{switchDataVisible.status === 1 ? 'Hide' : 'Visible'}</span> user{' '}
            {switchDataVisible.name}?
          </p>
          <div className="flex flex-row justify-around">
            <Button secondary onClick={closeModal}>
              Cancel
            </Button>
            <Button onClick={onConfirm}>Confirm</Button>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};

const RoleConfirmationModal = ({ open, userRoleData, closeModal, onConfirm }) => {
  /* const makeSwitchOnText =
    switchData && switchData.type === switchTypes.userStatus ? 'Enable' : 'give admin permissions to';
  const makeSwitchOffText =
    switchData && switchData.type === switchTypes.userStatus ? 'Enable' : 'revoke admin permissions from'; */

  return (
    <Modal show={open} dismissModal={closeModal}>
      {userRoleData ? (
        <div>
          {/* <p className="text-2xl mx-8 my-4">{switchData.status === 1 ? 'Disable' : 'Enable'} user?</p> */}
          <p className="text-lg mx-8 mb-8">
            Are you sure you want to change the role of <strong>{userRoleData.userName}</strong> to{' '}
            <strong>{roleNames[userRoleData.selectedRole]}</strong> ?
          </p>
          <div className="flex flex-row justify-around">
            <Button secondary onClick={closeModal}>
              Cancel
            </Button>
            <Button onClick={onConfirm}>Confirm</Button>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};

const DeleteConfirmationModal = ({ open, deleteUserData, closeModal, onConfirm }) => {
  return (
    <Modal show={open} dismissModal={closeModal}>
      {deleteUserData && deleteUserData.isEnabled == 0 ? (
        <div>
          <p className="text-2xl mx-8 my-4">Delete user?</p>
          <p className="text-lg mx-8 mb-8">
            Are you sure you want to delete <strong>{deleteUserData.userName}</strong> ?
          </p>
          <div className="flex flex-row justify-around">
            <Button secondary onClick={closeModal}>
              Cancel
            </Button>
            <Button onClick={onConfirm}>Confirm</Button>
          </div>
        </div>
      ) : (
        <div className="my-4">
          <p className="text-lg mx-8 mb-8">You need to disable user to perfom delete</p>
          <div className="flex justify-center item-center">
            <Button secondary onClick={closeModal}>
              OK
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

const Users = () => {
  const { authState, fetchLatestDisabledUsersCount } = useContext(AppContext);
  const [showHiddenUser, setShowHiddenUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statusChanging, setStatusChanging] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [showRoleModal, setShowRoleModal] = useState(null);
  const [showVisibilityModal, setShowVisibilityModal] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const [switchData, setSwitchData] = useState(null);
  const [switchDataVisible, setSwitchDataVisible] = useState(null);
  const [deleteUserData, setDeleteUserData] = useState(null);

  //selected user and role
  const [userRoleData, setUserRoleData] = useState(null);

  //update count
  const [updateCount, setUpdateCount] = useState(0);

  const columnConfiguration = useMemo(
    () => [
      {
        Header: 'Full Name',
        accessor: 'name',
        Filter: ColumnFilter,
        disableSortBy: true,
      },
      {
        Header: 'No. of Submissions',
        accessor: 'submissions',
        disableFilters: true,
        maxWidth: 100,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Filter: ColumnFilter,
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'isRegistered',
        Filter: ({ column }) => {
          return (
            <select
              className="rounded border border-gray-400 hover:border-gray-500 cursor-pointer px-2 py-1 w-full"
              value={column.filterValue}
              onChange={(e) => column.setFilter(e.target.value || undefined)}>
              <option value="">All</option>
              <option value={1}>Registered</option>
              <option value={0}>Not Registered</option>
            </select>
          );
        },
        Cell: ({ value }) => {
          return <p>{value === 0 ? 'Not Registered' : 'Registered'}</p>;
        },
        disableSortBy: true,
        maxWidth: 100,
      },
      {
        Header: 'Date Added',
        accessor: (original) => parseISO(original.createdAt).getTime(),
        disableFilters: true,
        Cell: ({ value }) => {
          return format(new Date(value), 'do LLL yyyy hh:mm a');
        },
      },
      {
        Header: 'Enabled Status',
        accessor: 'isEnabled',
        Filter: ({ column }) => {
          return (
            <select
              className="rounded border border-gray-400 hover:border-gray-500 cursor-pointer px-2 py-1 w-full"
              value={column.filterValue}
              onChange={(e) => column.setFilter(e.target.value || undefined)}>
              <option value="">All</option>
              <option value={1}>Enabled</option>
              <option value={0}>Disabled</option>
            </select>
          );
        },
        Cell: (props) => {
          const switchChangedHandler = () => {
            setShowModal(true);
            setSwitchData({
              type: switchTypes.userStatus,
              status: props.value,
              userId: props.row.original.userId,
              name: props.row.original.name,
            });
          };
          return (
            <>
              <label className="flex flex-col">
                <span className="mb-1">{props.value === 0 ? 'Disabled' : 'Enabled'}</span>
                <Switch onChange={switchChangedHandler} checked={props.value === 1} />
              </label>
            </>
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Role',
        accessor: 'role',
        filter: filterAdmins,
        Filter: ({ column }) => {
          return (
            <select
              className="rounded border border-gray-400 hover:border-gray-500 cursor-pointer px-2 py-1 w-full"
              value={column.filterValue}
              onChange={(e) => column.setFilter(e.target.value || undefined)}>
              <option value="">All</option>
              <option value="admin">Admin</option>
              <option value="line_manager">Line Manager</option>
              <option value="team_member">Team Member</option>
            </select>
          );
        },
        Cell: (props) => {
          const switchChangedHandler = () => {
            setShowModal(true);
            setSwitchData({
              type: switchTypes.userIsAdmin,
              status: props.value,
              userId: props.row.original.userId,
              name: props.row.original.name,
            });
          };
          let userRole = props.value;

          const roleChangedHandler = (value) => {
            //console.log(e.currentTarger);
            setShowRoleModal(true);
            setUserRoleData({
              userId: props.row.original.userId,
              userName: props.row.original.name,
              selectedRole: value,
            });
            /* setSwitchData({
              type: switchTypes.userIsAdmin,
              status: props.value,
              userId: props.row.original.userId,
              name: props.row.original.name,
            }); */
          };

          return (
            <>
              <label className="flex flex-col">
                <span className="mb-1 hidden">
                  {props.value === 0 ? 'Team member' : props.value === 1 ? 'Admin' : 'Super Admin'}
                </span>
                {props.value < 2 && <Switch onChange={switchChangedHandler} checked={props.value > 0} />}
              </label>

              <label class="role-container">
                Admin
                <input
                  type="radio"
                  checked={userRole == 'admin'}
                  name={'radio' + props.row.original.userId}
                  value={'admin'}
                  onChange={(e) => roleChangedHandler('admin')}
                />
                <span class="checkmark"></span>
              </label>
              <label class="role-container">
                Line Manager
                <input
                  type="radio"
                  checked={userRole == 'line_manager'}
                  name={'radio' + props.row.original.userId}
                  value={'line_manager'}
                  onChange={(e) => roleChangedHandler('line_manager')}
                />
                <span class="checkmark"></span>
              </label>
              <label class="role-container">
                Team Member
                <input
                  type="radio"
                  checked={userRole == 'team_member'}
                  name={'radio' + props.row.original.userId}
                  value={'team_member'}
                  onChange={(e) => roleChangedHandler('team_member')}
                />
                <span class="checkmark"></span>
              </label>
            </>
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Action',
        accessor: 'isVisible',
        Cell: (props) => {
          const switchChangedHandler = () => {
            setShowVisibilityModal(true);
            setSwitchDataVisible({
              userId: props.row.original.userId,
              status: props.value,
              name: props.row.original.name,
            });
          };
          const deleteHandler = () => {
            setShowDeleteModal(true);
            setDeleteUserData({
              userId: props.row.original.userId,
              //status: props.value,
              isEnabled: props.row.original.isEnabled,
              isVisible: props.row.original.isVisible,
              name: props.row.original.name,
            });
          };
          return (
            <>
              <label
                className={
                  'flex flex-col ' +
                  (props.row.original.isEnabled == '1' && props.value === 1 ? ' opacity-30' : 'opacity-100')
                }>
                <span className="mb-1">{props.value === 1 ? 'Visible' : 'Hidden'}</span>
                <Switch
                  onChange={
                    props.row.original.isEnabled == 1 && props.row.original.isVisible == 1
                      ? () => {}
                      : switchChangedHandler
                  }
                  checked={props.value === 1}
                />
              </label>
              <button
                disabled={props.row.original.isEnabled}
                className={
                  'mt-4 p-2 font-semibold rounded-lg ' +
                  (!props.row.original.isEnabled ? 'bg-red-600 text-white' : 'bg-red-300 text-base opacity-50')
                }
                onClick={deleteHandler}>
                Delete
              </button>
            </>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const loadUsersLIst = useCallback(() => {
    axios
      .get(serviceRoutes.users + '?show_hidden=' + (showHiddenUser ? '1' : '0'), {
        headers: {
          Authorization: `Bearer ${authState.accessToken}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (!res.data.error) {
          setUsers(res.data);
        } else {
          setErrorMessage('There was a problem fetching responses.');
        }
      })
      .catch(() => setErrorMessage('There is a problem with the network.'));
  }, [authState.accessToken, showHiddenUser]);

  const changeUserStatus = useCallback(async () => {
    const { status, userId } = switchData;
    const newStatus = status === 1 ? 0 : 1;
    if (userId) {
      try {
        setStatusChanging(true);
        const { data: response } = await axiosInstance.patch(
          serviceRoutes.updateUserStatus(userId),
          {
            status: status === 1 ? 0 : 1,
          },
          {
            headers: {
              Authorization: `Bearer ${authState.accessToken}`,
            },
          },
        );
        const index = users.findIndex((user) => user.userId === userId);
        const newUsers = [...users];
        newUsers[index].isEnabled = newStatus;
        setUsers(newUsers);
      } catch (error) {
        setErrorMessage(error.message || ERROR_MESSAGES.unexpected);
      }
    }
  }, [authState.accessToken, switchData, users]);

  const changeUserVisibility = useCallback(async () => {
    const { status, userId } = switchDataVisible;
    const newStatus = status === 1 ? 0 : 1;
    if (userId) {
      try {
        setStatusChanging(true);
        const { data: response } = await axiosInstance.patch(
          serviceRoutes.updateUserVisibility(userId),
          {
            status: status === 1 ? 0 : 1,
          },
          {
            headers: {
              Authorization: `Bearer ${authState.accessToken}`,
            },
          },
        );
        setUpdateCount(updateCount + 1);
        /* const index = users.findIndex((user) => user.userId === userId);
        const newUsers = [...users];
        newUsers[index].isVisible = newStatus;
        setUsers(newUsers);*/
      } catch (error) {
        setErrorMessage(error.message || ERROR_MESSAGES.unexpected);
      }
    }
  }, [authState.accessToken, switchDataVisible, users]);

  const changeUserAdminStatus = useCallback(async () => {
    const { selectedRole, userId } = userRoleData;
    if (userId) {
      try {
        setStatusChanging(true);
        const { data: response } = await axiosInstance.patch(
          serviceRoutes.updateUserAdminStatus(userId),
          {
            role: selectedRole,
          },
          {
            headers: {
              Authorization: `Bearer ${authState.accessToken}`,
            },
          },
        );
        const index = users.findIndex((user) => user.userId === userId);
        const newUsers = [...users];
        newUsers[index].role = selectedRole;

        setUsers(newUsers);
      } catch (error) {
        setErrorMessage(error.message || ERROR_MESSAGES.unexpected);
      }
    }
  }, [authState.accessToken, userRoleData, users]);

  const deleteUser = useCallback(async () => {
    const { userId } = deleteUserData;
    //const newStatus = status === 1 ? 0 : 1;
    if (userId) {
      try {
        setStatusChanging(true);
        const { data: response } = await axiosInstance.delete(serviceRoutes.deleteUser(userId), {
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
          },
        });
        const index = users.findIndex((user) => user.userId === userId);
        //const newUsers = [...users];
        const newUsers = users.filter((user) => user.userId !== userId);
        //newUsers[index].isVisible = newStatus;
        setUsers(newUsers);
      } catch (error) {
        setErrorMessage(error.message || ERROR_MESSAGES.unexpected);
      }
    }
  }, [authState.accessToken, deleteUserData, users]);

  const getRowId = useCallback((row) => {
    return row.userId;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    loadUsersLIst();
  }, [loadUsersLIst, updateCount]);

  return (
    <>
      {authState.permissions?.user && authState.permissions.user['read:any'] ? (
        <>
          {
            <ConfirmationModal
              open={showModal}
              switchData={switchData}
              closeModal={() => setShowModal(false)}
              onConfirm={() =>
                switchData.type === switchTypes.userStatus
                  ? changeUserStatus().finally(() => {
                      setShowModal(false);
                      fetchLatestDisabledUsersCount();
                    })
                  : changeUserAdminStatus().finally(() => {
                      setShowModal(false);
                    })
              }
            />
          }

          {
            <RoleConfirmationModal
              open={showRoleModal}
              userRoleData={userRoleData}
              closeModal={() => setShowRoleModal(false)}
              onConfirm={() =>
                changeUserAdminStatus().finally(() => {
                  setShowRoleModal(false);
                })
              }
            />
          }

          {
            <VisibilityConfirmationModal
              open={showVisibilityModal}
              switchDataVisible={switchDataVisible}
              closeModal={() => setShowVisibilityModal(false)}
              onConfirm={() =>
                changeUserVisibility().finally(() => {
                  setShowVisibilityModal(false);
                })
              }
            />
          }
          {
            <DeleteConfirmationModal
              open={showDeleteModal}
              deleteUserData={deleteUserData}
              closeModal={() => setShowDeleteModal(false)}
              onConfirm={() =>
                deleteUser().finally(() => {
                  setShowDeleteModal(false);
                })
              }
            />
          }
          <div className="w-full pb-40">
            <div className="flex justify-between ml-8 my-8">
              <p className="text-3xl text-primary font-medium ">Users</p>
              <label className="flex">
                <span className="mb-1 mr-1">Show hidden</span>
                <Switch
                  onChange={() => {
                    setShowHiddenUser(showHiddenUser ? false : true);
                  }}
                  checked={showHiddenUser === true}
                />
              </label>
            </div>

            {isLoading ? (
              <div className="w-full flex justify-center py-40">
                <Spinner className="h-12 w-12 text-primary" />
              </div>
            ) : (
              <>
                <div className="overflow-x-auto">
                  <DataTable columns={columnConfiguration} data={users} filtering={true} getRowId={getRowId} />
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <Page404 />
      )}
    </>
  );

  return (
    <>
      {
        <ConfirmationModal
          open={showModal}
          switchData={switchData}
          closeModal={() => setShowModal(false)}
          onConfirm={() =>
            switchData.type === switchTypes.userStatus
              ? changeUserStatus().finally(() => {
                  setShowModal(false);
                  fetchLatestDisabledUsersCount();
                })
              : changeUserAdminStatus().finally(() => {
                  setShowModal(false);
                })
          }
        />
      }
      <div className="w-full pb-40">
        <p className="text-3xl text-primary font-medium ml-8 my-8">Users</p>
        {isLoading ? (
          <div className="w-full flex justify-center py-40">
            <Spinner className="h-12 w-12 text-primary" />
          </div>
        ) : (
          <div className="overflow-x-auto">
            <DataTable columns={columnConfiguration} data={users} filtering={true} getRowId={getRowId} />
          </div>
        )}
      </div>
    </>
  );
};

export default Users;
