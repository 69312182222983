import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './screens/Login';
import Submissions from './screens/Submissions';
import Users from './screens/Users';
import SubmissionDetails from './screens/SubmissionDetails';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { appRoutes } from './constants/appRoutes';
import { Page404 } from './screens/404/404';
import { ForgotPassword } from './screens/ForgotPassword';
import { ResetPassword } from './screens/ResetPassword';

import Links from './screens/Links';
import LinkDetails from './screens/LinkDetails';

export const App = () => {
  return (
    <div className="bg-gray-100 min-w-screen min-h-screen flex items-center justify-center">
      <Switch>
        <PrivateRoute path={appRoutes.linkDetails}>
          <LinkDetails />
        </PrivateRoute>

        <PrivateRoute exact path={appRoutes.links}>
          <Links />
        </PrivateRoute>

        <PrivateRoute path={appRoutes.submissionDetails}>
          <SubmissionDetails />
        </PrivateRoute>
        <PrivateRoute exact path={[appRoutes.submissions]}>
          <Submissions />
        </PrivateRoute>
        <PrivateRoute path={appRoutes.users}>
          <Users />
        </PrivateRoute>
        <Route path={appRoutes.login}>
          <Login />
        </Route>
        <Route exact path={appRoutes.forgotPassword}>
          <ForgotPassword />
        </Route>
        <Route exact path={`${appRoutes.resetPassword}/:token`}>
          <ResetPassword />
        </Route>
        <Redirect from="/" to={appRoutes.submissions} exact />
        <PrivateRoute>
          <Route>
            <Page404 />
          </Route>
        </PrivateRoute>
      </Switch>
    </div>
  );
};
