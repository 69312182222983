import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import axios from '../axios/axios-instance';
import Button from '../components/Shared/Button';
import { appRoutes } from '../constants/appRoutes';
import { serviceRoutes } from '../serviceRoutes';

export const ResetPassword = () => {
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(null);
  const userType = useRef(0);
  const { token } = useParams();
  const { handleSubmit, register, formState, errors, getValues } = useForm({});

  const onSubmit = (data) => {
    if (token) {
      axios
        .patch(serviceRoutes.resetPassword, { password: data.password, token: token })
        .then((res) => {
          if (res.status === 200) {
            if ('isManagement' in res.data) {
              userType.current = res.data.isManagement;
            }
            setPasswordResetSuccess(true);
          }
        })
        .catch(() => {
          setPasswordResetSuccess(false);
        });
    }
  };

  return (
    <div className='h-full flex flex-col items-center'>
      {passwordResetSuccess === null ? (
        <>
          <h1 className='text-3xl text-primary font-medium mb-4'>Change Password</h1>
          <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-96 mb-48' id='change-password-form'>
            <input
              className='py-2 px-4 text-base rounded-md border border-secondary hover:border-black mt-4'
              placeholder='New Password'
              name='password'
              type='password'
              disabled={formState.isSubmitting}
              ref={register({
                required: 'Your password is required',
                minLength: {
                  value: 8,
                  message: 'Password must have at least 8 characters',
                },
              })}
            />
            {errors.password && <span className='text-red-600 font-medium'>{errors.password.message}</span>}
            <input
              className='py-2 px-4 text-base rounded-md border border-secondary hover:border-black mt-4'
              placeholder='Re-enter Your New Password'
              name='password_repeat'
              type='password'
              disabled={formState.isSubmitting}
              ref={register({ validate: (value) => value === getValues('password') || 'Passwords do not match' })}
            />
            {errors.password_repeat && (
              <span className='text-red-600 font-medium'>{errors.password_repeat.message}</span>
            )}

            <Button type='submit' className='mt-8' disabled={formState.isSubmitting} form='change-password-form'>
              Change Password
            </Button>
          </form>
        </>
      ) : (
        <div className='max-w-sm bg-white border-2 border-gray-300 rounded-md tracking-wide shadow-lg text-lg p-4'>
          {passwordResetSuccess === false ? (
            <>
              Password reset failed! The link is not valid. Please try again by creating a new reset link{' '}
              <Link
                className='underline text-center text-blue-600 hover:text-blue-800 visited:text-purple-600'
                to={appRoutes.forgotPassword}>
                here
              </Link>
            </>
          ) : (
            <>

              <p>
                {userType.current < 1 ?
                  'Your password has been changed successfully, Please sign in through your mobile app' :
                  'Your password has been changed successfully! You can now sign in with your new password'
                }
              </p>
              {userType.current > 0 &&
              <Link
                className='underline text-center text-blue-600 hover:text-blue-800 visited:text-purple-600'
                to={appRoutes.login}>
                Go back to login page?
              </Link>
              }
            </>
          )}
        </div>
      )}
    </div>
  );
};
