import React, { createContext, useEffect, useState } from 'react';
import axios from '../axios/axios-instance';
import { serviceRoutes } from '../serviceRoutes';

export const AppContext = createContext({
  authState: {
    accessToken: null,
    refreshToken: null,
    role: null,
  },
  setAuthState: () => console.error("This method hasn't been implemented yet"),
  latestDisabledUsersCount: null,
  fetchLatestDisabledUsersCount: () => console.error("This method hasn't been implemented yet"),
});

export const AppProvider = ({ children }) => {
  const [latestDisabledUsersCount, setLatestDisabledUsersCount] = useState(null);
  const [authState, setAuthState] = useState({
    accessToken: null,
    refreshToken: null,
  });

  const fetchLatestDisabledUsersCount = () => {
    axios
      .get(serviceRoutes.getDisabledUsersStatus, {
        headers: {
          Authorization: `Bearer ${authState.accessToken}`,
        },
      })
      .then((response) => {
        if (!response.error) {
          setLatestDisabledUsersCount(response.data[0].count);
        }
      })
      .catch((error) => {
        console.error('Failed to fetch disabled users', String(error.message));
      });
  };

  useEffect(() => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      axios
        .post(serviceRoutes.refreshToken, { refreshToken })
        .then((response) => {
          if (!response.error) {
            setAuthState(response.data);
            localStorage.setItem('refreshToken', response.data.refreshToken);
          }
        })
        .catch((error) => {});
    }
  }, []);
  return (
    <AppContext.Provider
      value={{
        authState,
        setAuthState,
        latestDisabledUsersCount,
        fetchLatestDisabledUsersCount,
      }}>
      {children}
    </AppContext.Provider>
  );
};
