import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { AppProvider } from './contexts/AppContext';
import { BrowserRouter } from 'react-router-dom';

import './assets/tailwind.output.css';
import './assets/custom.css';

ReactDOM.render(
  <AppProvider>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </AppProvider>,
  document.getElementById('root'),
);
