import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { unparse } from 'papaparse/papaparse.min';
import { format } from 'date-fns';
import { Spinner } from '../components/Shared/Spinner';
import { DataTable } from '../components/DataTable/DataTable';
import axios from '../axios/axios-instance';
import { SubmissionFiltering } from '../components/DataTable/SubmissionFiltering';
import { AppContext } from '../contexts/AppContext';
import { serviceRoutes } from '../serviceRoutes';
import StatusBadge from '../components/StatusBadge/StatusBadge';
import Button from '../components/Shared/Button';
import {
  getAssignedTo,
  getCategory,
  getDate,
  getEmail,
  getLink,
  getNotes,
  getStatus,
  getSubmittedBy,
  uniqueTextFieldKeys,
} from '../utils/csvHelper';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Page404 } from './404/404';

const columnConfiguration = [
  {
    Header: 'Title',
    accessor: 'title',
    disableSortBy: true,
  },
  {
    Header: 'Link',
    accessor: 'link',
    Cell: ({ value }) => {
      return value;
    },
    disableSortBy: true,
  },
];

const Links = () => {
  const { authState } = useContext(AppContext);
  const [responses, setResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();

  const handleExportCsv = async () => {
    try {
      const csvFile = unparse(generateCSVData(responses), { quotes: true });
      const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, `AAOK-Submissions_${new Date().toLocaleDateString()}.csv`);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', `AAOK-Submissions_${new Date().toLocaleDateString()}.csv`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const generateCSVData = (data) => {
    const csvHead = [
      'Title',
      'Link',
      'Email',
      'Date',
      'Date Submitted',
      'Assigned to',
      'Status',
      'Notes',
      'Link to Details Page',
    ];
    const defaultHeadersCount = csvHead.length;
    const dynamicHeaderCount = Object.keys(uniqueTextFieldKeys).length;
    for (const key in uniqueTextFieldKeys) {
      if (uniqueTextFieldKeys.hasOwnProperty(key)) {
        csvHead.push(uniqueTextFieldKeys[key]);
      }
    }

    const finalCsvData = data.map((r) => {
      const x = Array(defaultHeadersCount + dynamicHeaderCount).fill('N/A');

      x[0] = getCategory(r);
      x[1] = getSubmittedBy(r);
      x[2] = getEmail(r);
      x[3] = getDate(r);
      x[5] = getAssignedTo(r);
      x[6] = getStatus(r);
      x[7] = getNotes(r);
      x[8] = getLink(r);
      r.extraFields.forEach((y) => {
        switch (y.fieldKey) {
          case 'autoDateTime':
            x[4] = y.value;
            return;
          case 'commentActions':
            x[9] = y.value;
            return;
          case 'commentImprove':
            x[10] = y.value;
            return;
          case 'commentIssues':
            x[11] = y.value;
            return;
          case 'commentObservations':
            x[12] = y.value;
            return;
          case 'contractName':
            x[13] = y.value;
            return;
          case 'descriptionOfWorks':
            x[14] = y.value;
            return;
          case 'directorOrManager':
            x[15] = y.value;
            return;
          case 'incident':
            x[16] = y.value;
            return;
          case 'myProblem':
            x[17] = y.value;
            return;
          case 'myIdea':
            x[18] = y.value;
            return;
          case 'supervisor':
            x[19] = y.value;
            return;
          case 'theirName':
            x[20] = y.value;
            return;
          case 'whatTheKudosFor':
            x[21] = y.value;
            return;
          case 'whatTheProblemIs':
            x[22] = y.value;
            return;
          case 'whyItWouldHelp':
            x[23] = y.value;
            return;
          default:
            return;
        }
      });
      return x;
    });
    return {
      fields: csvHead,
      data: finalCsvData,
    };
  };

  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [newEntryCount, setCount] = useState(0);
  const { register, handleSubmit } = useForm();

  const linkClickHandler = useCallback(
    ({ linkId }) => {
      history.push('/links/' + linkId);
    },
    [history],
  );

  const getRowId = useCallback(({ linkId }) => {
    return linkId;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(serviceRoutes.getLinks, {
        headers: {
          Authorization: `Bearer ${authState.accessToken}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (!res.data.error) {
          setResponses(res.data);
        } else {
          setErrorMessage('There was a problem fetching responses.');
        }
      })
      .catch(() => setErrorMessage('There is a problem with the network.'));
  }, [authState.accessToken, newEntryCount]);

  const onSubmit = useCallback(
    (formData) => {
      axios
        .post(serviceRoutes.createLink, formData, {
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
          },
        })
        .then((res) => {
          setCount(newEntryCount + 1);
        })
        .catch((err) => {
          setErrorMessage('Could not submit admin fields');
        });
    },
    [authState.accessToken],
  );

  return (
    <>
      {authState.permissions?.link && authState.permissions.link['read:any'] ? (
        <div className="w-full pb-40">
          <p className="text-3xl text-primary font-medium ml-8 my-8">Useful Links</p>
          {isLoading ? (
            <div className="w-full flex justify-center py-40">
              <Spinner className="h-12 w-12 text-primary" />
            </div>
          ) : (
            <>
              <div className="mb-16">
                <form onSubmit={handleSubmit(onSubmit)} id="admin-form">
                  <div className="grid grid-cols-2 gap-4">
                    <div className=" my-4 w-full">
                      <p className="text-lg font-medium my-2">Title: </p>
                      <textarea
                        name="title"
                        ref={register}
                        className="h-12 rounded border border-gray-400 hover:border-gray-500 cursor-pointer px-2 py-1 w-full"
                        placeholder="Enter your title"
                        defaultValue=""
                        required
                      />
                    </div>
                    <div className=" my-4 w-full">
                      <p className="text-lg font-medium my-2">Link: </p>
                      <textarea
                        name="link"
                        ref={register}
                        className="h-12 rounded border border-gray-400 hover:border-gray-500 cursor-pointer px-2 py-1 w-full"
                        placeholder="Enter your link"
                        defaultValue=""
                        required
                      />
                    </div>
                  </div>

                  <div className="flex">
                    <Button className="w-12 mr-4" type="submit" form="admin-form">
                      Submit
                    </Button>
                    <Button className="w-12 hidden" secondary>
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>

              {/* <Button isDisabled={responses.length < 1} onClick={handleExportCsv} className="ml-auto mb-4">
            Export to CSV
          </Button> */}

              <DataTable
                rowClickHandler={linkClickHandler}
                columns={columnConfiguration}
                data={responses || []}
                getRowId={getRowId}
              />
            </>
          )}
        </div>
      ) : (
        <Page404 />
      )}
    </>
  );
};

export default Links;
