import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import axios from '../axios/axios-instance';
import { AppContext } from '../contexts/AppContext';
import { Spinner } from '../components/Shared/Spinner';
import { serviceRoutes } from '../serviceRoutes';
import Button from '../components/Shared/Button';
import { format } from 'date-fns';
import StatusBadge, { EStatus } from '../components/StatusBadge/StatusBadge';
import './SubmissionDetails.css';

const SubmissionDetails = () => {
  const { id } = useParams();
  const { authState } = useContext(AppContext);
  const [details, setDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { register, handleSubmit } = useForm();

  const [checklists, setChecklists] = useState([]);
  const [checklistFirstIndex, setIndex] = useState(-1);

  const fetchSubmissionDetails = useCallback(() => {
    setIsLoading(true);
    axios
      .get(serviceRoutes.getSubmissionDetails(id), {
        headers: {
          Authorization: `Bearer ${authState.accessToken}`,
        },
      })
      .then((res) => {
        if (!res.data.error) {
          setDetails(res.data);
          setIsLoading(false);
        } else {
          setErrorMessage('There was a problem fetching responses.');
        }
      })
      .catch(() => setErrorMessage('There is a problem with the network.'))
      .finally(() => setIsEditing(false));
  }, [authState.accessToken, id]);

  useEffect(() => {
    if (details.answers && details.answers.length > 0) {
      setChecklists(details.answers.filter((item) => item.value.type == 9));
      setIndex(details.answers.findIndex((item) => item.value.type == 9));
      console.log(checklists);
    }
  }, [details]);

  const onSubmit = useCallback(
    (formData) => {
      axios
        .patch(serviceRoutes.setAdminFields(id), formData, {
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
          },
        })
        .then((res) => {
          fetchSubmissionDetails();
        })
        .catch((err) => {
          setErrorMessage('Could not submit admin fields');
        });
    },
    [authState.accessToken, fetchSubmissionDetails, id],
  );

  const getAssignedToFromName = useCallback(
    (userObj, toFromId) => {
      if (userObj) {
        return userObj.firstName + ' ' + userObj.lastName;
      } else if (!userObj && toFromId) {
        return <span className="text-red-500">Deleted user</span>;
      } else {
        return '';
      }
    },
    [id],
  );

  useEffect(() => {
    fetchSubmissionDetails();
  }, [fetchSubmissionDetails]);

  return (
    <div className="w-full flex-wrap">
      <div className="w-full pb-40">
        {isLoading ? (
          <Spinner />
        ) : (
          details.answers.length > 0 && (
            <div className="mx-4">
              <p className="font-medium text-2xl my-8 gri">{`${details.title} - ${details.description}`}</p>
              <p className="text-lg my-2">{`Submitter name: ${details.submitter}`}</p>
              {details.email && <p className="text-lg my-2">{`Email: ${details.email}`}</p>}

              <p className="font-medium text-2xl my-8 underline">Form Entries:</p>

              <div className="w-full container">
                {details.answers &&
                  details.answers.map((entry, index) => {
                    switch (FieldType[entry.value.type]) {
                      case 'TEXT':
                        return (
                          <div className="my-4" key={index}>
                            <p className="text-lg font-medium">{`${entry.label}: `}</p>
                            <p>{`${entry.value.value}`}</p>
                          </div>
                        );
                      case 'NUMERIC':
                      case 'LOCATION':
                        return <Map key={index} lat={entry.value.latitude} lng={entry.value.longitude} />;
                      case 'CURRENT_DATETIME':
                        return (
                          <div className="my-4" key={index}>
                            <p className="text-lg font-medium">Date Submitted: </p>
                            <p>{`${entry.value.value}`}</p>
                          </div>
                        );
                      case 'DATE':
                      case 'TIME':
                      case 'NAME_OR_ANONYMOUS':
                      case 'TEXTAREA':
                        return (
                          <div className="my-4" key={index}>
                            <p className="text-lg font-medium">{`${entry.label}: `}</p>
                            <p className="whitespace-pre-wrap">{`${entry.value.value.replace()}`}</p>
                          </div>
                        );
                      case 'PHOTO':
                        return <Photo key={index} imageName={entry.value.value} />;
                      case 'TICK_LIST':
                        /* return (
                          <TickList
                            key={index}
                            listItems={entry.jsonConfig.listItems}
                            checkItems={entry.jsonConfig.checkItems}
                            value={entry.value.value}
                          />
                        ); */
                        return (
                          <div>
                            {checklistFirstIndex == index &&
                              checklists.map((checklist, indexK) => (
                                <>
                                  <p key={indexK} className={checklist.value.value == 1 ? 'font-medium' : ''}>
                                    {checklist.value.value == 1 ? '✓ ' : ''}
                                    {checklist.label}
                                  </p>
                                  <br />
                                </>
                              ))}
                          </div>
                        );
                      case 'SIGNATURE':
                      default:
                        return null;
                    }
                  })}
              </div>

              <hr className="border-gray-300 border-2 my-8" />

              <p className="font-medium text-2xl underline">Admin Actions:</p>
              {isEditing ? (
                <form onSubmit={handleSubmit(onSubmit)} id="admin-form">
                  <div className="flex flex-col my-4">
                    <p className="text-lg font-medium my-2">Assigned to: </p>
                    <select
                      ref={register}
                      name="assignedTo"
                      className="w-72 rounded border border-gray-400 hover:border-gray-500 cursor-pointer px-2 py-1"
                      width="480px"
                      defaultValue={details.assignedTo === null ? 0 : details.assignedToId}>
                      <option value={0}>None</option>
                      {details.adminUsers.map((user) => {
                        return (
                          <option key={user.user_id} value={user.user_id}>
                            {user.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="flex flex-col my-4">
                    <p className="text-lg font-medium my-2">Status: </p>
                    <select
                      ref={register}
                      name="status"
                      className="w-72 rounded border border-gray-400 hover:border-gray-500 cursor-pointer px-2 py-1"
                      width="480px"
                      defaultValue={details.status}>
                      <option value={EStatus.pending}>Pending</option>
                      <option value={EStatus.inProgress}>In Progress</option>
                      <option value={EStatus.resolved}>Resolved</option>
                    </select>
                  </div>
                  <div className="flex flex-col my-4 max-w-lg">
                    <p className="text-lg font-medium my-2">Notes: </p>
                    <textarea
                      name="notes"
                      ref={register}
                      className="h-24 rounded border border-gray-400 hover:border-gray-500 cursor-pointer px-2 py-1"
                      placeholder="enter your note here"
                      defaultValue={details.notes}
                    />
                  </div>
                  <div className="flex">
                    <Button className="w-12 mr-4" type="submit" form="admin-form">
                      Submit
                    </Button>
                    <Button className="w-12" secondary onClick={() => setIsEditing(false)}>
                      Cancel
                    </Button>
                  </div>
                </form>
              ) : (
                <div>
                  <div className="flex flex-col my-4">
                    <p className="text-lg font-medium my-2">Assigned to: </p>
                    <p>{details.assignedTo ? details.assignedTo : 'None'}</p>
                  </div>
                  <div className="flex flex-col my-4">
                    <p className="text-lg font-medium my-2">Status: </p>
                    <StatusBadge status={details.status} />
                  </div>
                  <div className="flex flex-col my-4">
                    <p className="text-lg font-medium my-2">Notes: </p>
                    <p className="whitespace-pre-wrap">{details.notes}</p>
                  </div>
                  <Button className="w-12" onClick={() => setIsEditing(true)}>
                    Edit
                  </Button>
                </div>
              )}
              {errorMessage && <p>{errorMessage}</p>}

              <p className="font-medium text-2xl underline mt-12 my-3">Activity Logs</p>
              {details.responseLogs.length > 0 && (
                <table className="w-full border border-gray-400">
                  <thead>
                    <th className="text-left py-3 px-6 border-t border-gray-400">Date &amp; Time</th>
                    <th className="text-left py-3 px-6 border-t border-gray-400">Assigned From</th>
                    <th className="text-left py-3 px-6 border-t border-gray-400">Assigned To</th>
                    <th className="text-left py-3 px-6 border-t border-gray-400">Status</th>
                    <th className="text-left py-3 px-6 border-t border-gray-400">Note</th>
                  </thead>
                  <tbody>
                    {details.responseLogs.map((logitem) => (
                      <tr>
                        <td className="text-left py-3 px-6 border-t border-gray-400">
                          {format(new Date(logitem.createdAt), 'do LLL yyyy HH:mm')}
                        </td>
                        <td className="text-left py-3 px-6 border-t border-gray-400">
                          {getAssignedToFromName(logitem.assignedFromUser, logitem.userId)}
                        </td>
                        <td className="text-left py-3 px-6 border-t border-gray-400">
                          {getAssignedToFromName(logitem.assignedToUser, logitem.assignedTo)}
                        </td>
                        <td className="text-left py-3 px-6 border-t border-gray-400">
                          {<StatusBadge status={logitem.status} />}
                        </td>
                        <td className="text-left py-3 px-6 border-t border-gray-400">{logitem.notes}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default SubmissionDetails;

const FieldType = [
  'TEXT',
  'NUMERIC',
  'LOCATION',
  'CURRENT_DATETIME',
  'DATE',
  'TIME',
  'NAME_OR_ANONYMOUS',
  'TEXTAREA',
  'PHOTO',
  'TICK_LIST',
  'SIGNATURE',
];

const Map = (props) => {
  const mapsApiKey = process.env.REACT_APP_MAPS_API_KEY;
  const { lat, lng } = props;
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${
    lat + ',' + lng
  }&zoom=15&size=600x400&maptype=roadmap
  &markers=color:red%7C${lat + ',' + lng}
  &key=${mapsApiKey}`;

  return (
    <div className="my-4">
      <p className="text-lg font-medium my-2">User's selected location: </p>
      <img src={mapUrl} alt="user selected map location" />
    </div>
  );
};

const TickList = ({ listItems, checkItems, value }) => {
  return (
    <>
      {listItems.map((listItem, listIndex) => (
        <div className="my-4" key={listIndex}>
          <p className="font-medium text-lg">{`${listItem}: `}</p>
          <div className="ml-4">
            {checkItems.map((checkItem, checkIndex) => {
              const selected = value[listIndex] === value[checkIndex];
              return (
                <p key={checkIndex} className={selected ? 'font-medium' : ''}>
                  {selected ? '✓ ' : ''}
                  {checkItem}
                </p>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
};

const Photo = ({ imageName }) => {
  const url = `${process.env.REACT_APP_IMAGE_URL}/${imageName}`;
  return (
    <div className="my-4">
      <p className="text-lg font-medium my-2">Uploaded Photo: </p>
      <img src={url} alt="uploaded by user" width="500" />
    </div>
  );
};
