import React from 'react';
import { Link } from 'react-router-dom';
import { appRoutes } from '../../constants/appRoutes';
import './404.css';

export const Page404 = () => {
  return (
    <div className="p-0 m-0 w-full" id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <div></div>
          <h1>404</h1>
        </div>
        <h2>Page not found</h2>
        <p>Are you lost?</p>
        <Link to={appRoutes.home}>Home Page</Link>
      </div>
    </div>
  );
};
