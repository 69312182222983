import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import axios from '../axios/axios-instance';
import { AppContext } from '../contexts/AppContext';
import { Spinner } from '../components/Shared/Spinner';
import { serviceRoutes } from '../serviceRoutes';
import Button from '../components/Shared/Button';
import StatusBadge, { EStatus } from '../components/StatusBadge/StatusBadge';
import './SubmissionDetails.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Modal from '../components/Modal/Modal';

const LinkDetails = () => {
  const { id } = useParams();
  const { authState } = useContext(AppContext);
  const [details, setDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const fetchLinkDetails = useCallback(() => {
    setIsLoading(true);
    axios
      .get(serviceRoutes.getLinkDetails(id), {
        headers: {
          Authorization: `Bearer ${authState.accessToken}`,
        },
      })
      .then((res) => {
        if (!res.data.error) {
          setDetails(res.data);
          setIsLoading(false);
        } else {
          setErrorMessage('There was a problem fetching responses.');
        }
      })
      .catch(() => setErrorMessage('There is a problem with the network.'))
      .finally(() => setIsEditing(false));
  }, [authState.accessToken, id]);

  const deleteLink = useCallback(() => {
    setIsLoading(true);
    axios
      .delete(serviceRoutes.deleteLink(id), {
        headers: {
          Authorization: `Bearer ${authState.accessToken}`,
        },
      })
      .then((res) => {
        if (!res.data.error) {
          history.push('/links');
        } else {
          setErrorMessage('There was a problem fetching responses.');
        }
      })
      .catch(() => setErrorMessage('There is a problem with the network.'))
      .finally(() => setIsEditing(false));
  }, [authState.accessToken, id]);

  const onSubmit = useCallback(
    (formData) => {
      axios
        .patch(serviceRoutes.updateLink(id), formData, {
          headers: {
            Authorization: `Bearer ${authState.accessToken}`,
          },
        })
        .then((res) => {
          //fetchLinkDetails();
          history.push('/links');
        })
        .catch((err) => {
          setErrorMessage('Could not submit admin fields');
        });
    },
    [authState.accessToken, fetchLinkDetails, id],
  );

  useEffect(() => {
    fetchLinkDetails();
  }, [fetchLinkDetails]);

  const [showModal, setShowModal] = useState(false);

  const DeleteConfirmationModal = ({ open, closeModal, onConfirm }) => {
    return (
      <Modal show={open} dismissModal={closeModal}>
        <div>
          <p className="text-2xl mx-8 my-4">Delete ?</p>
          <p className="text-lg mx-8 mb-8">Are you sure to delete ?</p>
          <div className="flex flex-row justify-around">
            <Button secondary onClick={closeModal}>
              Cancel
            </Button>
            <Button onClick={onConfirm}>Confirm</Button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className="w-full pb-40">
      <p className="text-3xl text-primary font-medium ml-8 my-8">Udate Link</p>
      {isLoading ? (
        <div className="w-full flex justify-center py-40">
          <Spinner className="h-12 w-12 text-primary" />
        </div>
      ) : (
        <>
          <DeleteConfirmationModal
            open={showModal}
            closeModal={() => setShowModal(false)}
            onConfirm={() => {
              deleteLink();
              setShowModal(false);
            }}
          />
          <div className="mb-16">
            <form onSubmit={handleSubmit(onSubmit)} id="admin-form">
              <div className="grid grid-cols-2 gap-4">
                <div className=" my-4 w-full">
                  <p className="text-lg font-medium my-2">Title: </p>
                  <textarea
                    name="title"
                    ref={register}
                    className="h-12 rounded border border-gray-400 hover:border-gray-500 cursor-pointer px-2 py-1 w-full"
                    placeholder="Enter your title"
                    defaultValue={details.title}
                  />
                </div>
                <div className=" my-4 w-full">
                  <p className="text-lg font-medium my-2">Link: </p>
                  <textarea
                    name="link"
                    ref={register}
                    className="h-12 rounded border border-gray-400 hover:border-gray-500 cursor-pointer px-2 py-1 w-full"
                    placeholder="Enter your link"
                    defaultValue={details.link}
                  />
                </div>
              </div>

              <div className="flex">
                <Button className="w-12 bg-green-500 hover:bg-green-600 text-white" type="submit" form="admin-form">
                  Update
                </Button>
                <Button
                  type="button"
                  className="w-12 ml-4 mr-4 bg-red-500 hover:bg-red-500 text-white"
                  secondary
                  onClick={() => setShowModal(true)}>
                  Delete
                </Button>
                <Button
                  type="button"
                  className="w-12 hover:bg-gray-300"
                  secondary
                  onClick={() => history.push('/links')}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default LinkDetails;

const FieldType = [
  'TEXT',
  'NUMERIC',
  'LOCATION',
  'CURRENT_DATETIME',
  'DATE',
  'TIME',
  'NAME_OR_ANONYMOUS',
  'TEXTAREA',
  'PHOTO',
  'TICK_LIST',
  'SIGNATURE',
];

const Map = (props) => {
  const mapsApiKey = process.env.REACT_APP_MAPS_API_KEY;
  const { lat, lng } = props;
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${
    lat + ',' + lng
  }&zoom=15&size=600x400&maptype=roadmap
  &markers=color:red%7C${lat + ',' + lng}
  &key=${mapsApiKey}`;

  return (
    <div className="my-4">
      <p className="text-lg font-medium my-2">User's selected location: </p>
      <img src={mapUrl} alt="user selected map location" />
    </div>
  );
};

const TickList = ({ listItems, checkItems, value }) => {
  return (
    <>
      {listItems.map((listItem, listIndex) => (
        <div className="my-4" key={listIndex}>
          <p className="font-medium text-lg">{`${listItem}: `}</p>
          <div className="ml-4">
            {checkItems.map((checkItem, checkIndex) => {
              const selected = value[listIndex] === value[checkIndex];
              return (
                <p key={checkIndex} className={selected ? 'font-medium' : ''}>
                  {selected ? '->' : ''}
                  {checkItem}
                </p>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
};

const Photo = ({ imageName }) => {
  const url = `${process.env.REACT_APP_IMAGE_URL}/${imageName}`;
  return (
    <div className="my-4">
      <p className="text-lg font-medium my-2">Uploaded Photo: </p>
      <img src={url} alt="uploaded by user" width="500" />
    </div>
  );
};
