import React from 'react';

export const EStatus = {
  pending: 0,
  inProgress: 1,
  resolved: 2,
};

export const StatusBadge = (props) => {
  const { status } = props;

  switch (status) {
    case EStatus.pending:
      return <div className="w-28 text-center rounded px-2 py-1 text-white font-medium bg-red-500">Pending</div>;
    case EStatus.inProgress:
      return <div className="w-28 text-center rounded px-2 py-1 text-white font-medium bg-yellow-500">In Progress</div>;
    case EStatus.resolved:
      return <div className="w-28 text-center rounded px-2 py-1 text-white font-medium bg-primary">Resolved</div>;
    default:
      return null;
  }
};

export default StatusBadge;
