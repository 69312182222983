export const appRoutes = {
  home: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  submissions: '/submissions',
  submissionDetails: '/submissions/:id',
  users: '/users',
  linkDetails: '/links/:id',
  links: '/links',
  updateLink: '/links/:id',
};
